import React, { useCallback, useMemo, useState } from 'react';

import cx from 'classnames';

import { formatVolumeWithStdDev, formatWellPosition } from 'common/lib/format';
import Colors from 'common/ui/Colors';
import { QuadraticBezierCurve } from 'common/ui/components/simulation-details/mix/edgeRouting';
import { ADJACENT_WELL_AVG_LENGTH_SQUARED } from 'common/ui/components/simulation-details/mix/EdgeSvg';
import {
  DeckItemState,
  Edge,
} from 'common/ui/components/simulation-details/mix/MixState';
import Tooltip from 'common/ui/components/Tooltip';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

/**
 * Amount of padding, in pixels, to add to the left and right of the edge label.
 */
const LABEL_X_PADDING = 8;
const LABEL_Y_PADDING = 3;
/**
 * Font size in pixels.
 */
const LABEL_FONT_SIZE = 12;
/**
 * For very short edges, the font size should be smaller.
 */
const LABEL_FONT_SIZE_SMALL = 8;
/**
 * If the label is too big to fit on the edge, then it will be shifted above the
 * label by this amount (in pixels).
 */
const OVERSIZED_LABEL_OFFSET = 3;

type Props = {
  edge: Edge;
  curve: QuadraticBezierCurve;
  pathLengthSquared: number;
  deckItemDict: Map<string, DeckItemState>;
  isSelected?: boolean;
  onClick?: (edge: Edge) => void;
  onMouseEnter?: (edge: Edge) => void;
  onMouseLeave?: () => void;
};

/**
 * An arrow from one deck position to another, with a label describing the
 * action.
 */
export default function EdgeLabel({
  edge,
  curve,
  pathLengthSquared,
  deckItemDict,
  isSelected,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: Props) {
  const classes = useStyles();

  // We can use the path length to determine the right fontSize for the label,
  // so that short paths (adjacent wells) won't have any text cropped
  const isShortPath = pathLengthSquared < ADJACENT_WELL_AVG_LENGTH_SQUARED;
  const computedFontSize = isShortPath ? LABEL_FONT_SIZE_SMALL : LABEL_FONT_SIZE;
  // Add padding to top and bottom
  const labelHeight = computedFontSize + LABEL_Y_PADDING * 2;
  const pathLength = Math.sqrt(pathLengthSquared);

  const handleMouseEnter = useCallback(() => onMouseEnter?.(edge), [edge, onMouseEnter]);
  const handleClick = useCallback(() => onClick?.(edge), [edge, onClick]);

  const labelText = useMemo(() => formatEdgeLabel(edge), [edge]);

  // The background rect will be sized based on the text width
  const [labelWidth, setLabelWidth] = useState<number>(0);
  // Usually labelY will be 0, which means it is on top of the arrow path.
  // However, if path is short then the label should be offset above the path.
  const [labelY, setLabelY] = useState<number>(0);

  // When the <text> element is mounted, get the width of the text and check if
  // oversized.
  const textRef = useCallback(
    (textEl: SVGTextElement | null) => {
      if (textEl) {
        // Add padding each side
        const labelWidth = textEl.getBBox().width + LABEL_X_PADDING * 2;
        setLabelWidth(labelWidth);
        if (labelWidth > pathLength) {
          // If the label is larger than the path, then offset it above the path.
          setLabelY(-labelHeight - OVERSIZED_LABEL_OFFSET);
        } else {
          // Offset the label by half the label height, such that it's centered
          // on the path.
          setLabelY(-labelHeight / 2);
        }
      }
    },
    [labelHeight, pathLength],
  );

  return (
    <Tooltip title={<EdgeTooltipTitle edge={edge} deckItemDict={deckItemDict} />}>
      <g
        transform={`translate(${curve.center.x},${curve.center.y}) rotate(${curve.angle})`}
      >
        <rect
          className={cx(classes.labelRect, {
            [classes.selectedLabelRect]: isSelected,
          })}
          x={-labelWidth / 2}
          y={labelY}
          height={labelHeight}
          width={labelWidth}
          rx={labelHeight / 4}
          ry={labelHeight / 4}
          onClick={handleClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={onMouseLeave}
          // Used to identify the click target in the MixScreen
          data-edge="true"
        />
        <text
          className={classes.labelText}
          ref={textRef}
          y={labelY + labelHeight / 2}
          dy={1}
          fontSize={computedFontSize}
        >
          {labelText}
        </text>
      </g>
    </Tooltip>
  );
}

type EdgeTooltipTitleProps = {
  edge: Edge;
  deckItemDict: Map<string, DeckItemState>;
};

function EdgeTooltipTitle({ edge, deckItemDict }: EdgeTooltipTitleProps) {
  const classes = useStyles();
  switch (edge.type) {
    case 'liquid_transfer':
    case 'liquid_dispense': {
      const liquidDestination = edge.action.liquidDestination;
      const tipLocation = edge.action.tipDestination.loc;
      const sourceDeckItem = deckItemDict.get(edge.action.from.loc.deck_item_id);
      const destDeckItem = deckItemDict.get(tipLocation.deck_item_id);

      return (
        <>
          <strong>Liquid Transfer</strong>
          <dl className={classes.tooltipTable}>
            <dt>Source</dt>
            <dd>
              {sourceDeckItem?.name} ({formatWellPosition(edge.from)})
            </dd>
            <dt>Destination</dt>
            <dd>
              {destDeckItem?.name} ({formatWellPosition(tipLocation)})
            </dd>
            {edge.action.multiDispenseCount > 1 && (
              <>
                <dt>Multi-dispense</dt>
                <dd>
                  {edge.action.multiDispenseIndex + 1} of {edge.action.multiDispenseCount}
                </dd>
              </>
            )}
            <dt>Dispense Volume</dt>
            <dd>{formatVolumeWithStdDev(edge.action.volume)}</dd>
            {edge.action.volume.bias !== undefined && (
              <>
                <dt>Predicted Systemic Error </dt>
                <dd>{edge.action.volume.bias + '%'}</dd>
              </>
            )}
            {edge.action.volume.p_failure !== undefined && (
              <>
                <dt>Predicted Probability of Failure </dt>
                <dd>{edge.action.volume.p_failure * 100.0 + '%'}</dd>
              </>
            )}
            {edge.action.errors !== undefined && (
              <>
                <dt>Predicted errors</dt>
                <dd>{edge.action.errors}</dd>
              </>
            )}
            {liquidDestination.volume_in_tip !== undefined && (
              <>
                <dt>Volume remaining in tip</dt>
                <dd>{formatVolumeWithStdDev(liquidDestination.volume_in_tip)}</dd>
              </>
            )}
            <dt>Policy</dt>
            <dd>{edge.action.policy}</dd>
            {edge.action.asp.device_liquid_class_name && (
              <>
                <dt>Aspiration Liquid Class</dt>
                <dd>{edge.action.asp.device_liquid_class_name}</dd>
              </>
            )}
            {edge.action.dsp.device_liquid_class_name && (
              <>
                <dt>Dispense Liquid Class</dt>
                <dd>{edge.action.dsp.device_liquid_class_name}</dd>
              </>
            )}
          </dl>
        </>
      );
    }
    case 'filtration': {
      const liquidDestination = edge.action.liquidDestination;
      const destDeckItem = deckItemDict.get(liquidDestination.loc.deck_item_id);
      return (
        <>
          Liquid Filtration
          <dl className={classes.tooltipTable}>
            <dt>Destination</dt>
            <dd>
              {destDeckItem?.name} ({formatWellPosition(edge.to)})
            </dd>
            <dt>Volume</dt>
            <dd>{formatVolumeWithStdDev(edge.action.volume)}</dd>
          </dl>
        </>
      );
    }
    case 'move_plate':
      return (
        <>
          Move {edge.deckItemTypes.join(' and ')} from {edge.fromDeckPositionName} to{' '}
          {edge.toDeckPositionName}
        </>
      );
  }
}

function formatEdgeLabel(edge: Edge): string {
  switch (edge.type) {
    case 'liquid_transfer':
    case 'liquid_dispense': {
      // For multi dispense steps where liquid remains in the tip, show state of
      // the tip, e.g. "50 ul (100 ul in tip)".
      const volumeInTip = edge.action.liquidDestination.volume_in_tip;
      return (
        formatVolumeWithStdDev(edge.action.volume) +
        (volumeInTip && volumeInTip.value > 0
          ? ` (${formatVolumeWithStdDev(volumeInTip)} in tip)`
          : '')
      );
    }
    case 'filtration':
      // The outflow of a robocolumn is approximate; an unknown amount of liquid
      // may have been lost or gained during filtration.
      return '~' + formatVolumeWithStdDev(edge.action.volume);
    case 'move_plate':
      return `Moving ${edge.deckItemTypes.join(' and ')}`;
  }
}

const useStyles = makeStylesHook(theme => ({
  edgeLabel: {
    fill: Colors.MIX_PREVIEW_LABEL,
    textAnchor: 'middle',
    pointerEvents: 'all',
  },
  labelText: {
    fill: Colors.MIX_PREVIEW_LABEL,
    textAnchor: 'middle',
    dominantBaseline: 'middle',
    fontWeight: 'normal',
  },
  labelRect: {
    fill: 'white',
    stroke: Colors.MIX_PREVIEW_EDGE,
    strokeWidth: 0.5,
    pointerEvents: 'all',
  },
  selectedLabelRect: {
    strokeWidth: 3,
    stroke: Colors.SELECTED_WELL_BORDER,
  },
  tooltipTable: {
    display: 'grid',
    gap: theme.spacing(2, 2),
    margin: theme.spacing(2, 0, 0, 0),
    fontWeight: 'normal',
    '& dt': {
      gridColumn: 1,
    },
    '& dd': {
      margin: 0,
      gridColumn: 2,
      wordBreak: 'break-all',
    },
  },
}));
