import React, { useCallback, useContext, useMemo, useState } from 'react';

import 'react-markdown-editor-lite/lib/index.css';

import { useMutation } from '@apollo/client';
import cx from 'classnames';
import MdEditor from 'react-markdown-editor-lite';

import { ExperimentDetailContext } from 'client/app/apps/experiments/ExperimentDetailScreen';
import { MUTATION_UPDATE_EXPERIMENT_MODULE } from 'client/app/apps/experiments/gql/mutations';
import { QUERY_EXPERIMENT } from 'client/app/apps/experiments/gql/queries';
import { TextBlock as GraphQLTextBlock } from 'client/app/gql';
import { Markdown, renderMarkdown } from 'common/lib/markdown';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDebounce from 'common/ui/hooks/useDebounce';

type Props = {
  experimentId: ExperimentId;
  block: GraphQLTextBlock;
};

// export default function TextBlock({ block, experimentId }: Props) {
//   const classes = useStyles();

type EditorProps = {
  value: Markdown;
  onChange: (markdown: Markdown) => void;
};

const EDITOR_STYLE = { minHeight: '200px', maxHeight: '80vh', width: '100%' };
const EDITOR_CONFIG = {
  view: {
    menu: true,
    md: true,
    html: true,
  },
  table: {
    maxRow: 5,
    maxCol: 6,
  },
  syncScrollMode: ['leftFollowRight', 'rightFollowLeft'],
};

function handleImageUpload(file: Blob) {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = data => {
      resolve(data?.target?.result);
    };
    reader.readAsDataURL(file);
  });
}

export function MarkdownEditor({ value, onChange }: EditorProps) {
  const classes = useStyles();
  const htmlClass = classes['preview']; // From preview.css
  const onEditorChange = useCallback(
    (data: { text: string; html: string }) => onChange(data.text as Markdown),
    [onChange],
  );
  const renderHTML = useCallback((text: string) => renderMarkdown(text as Markdown), []);

  /* We must explicitly pass in the plugins we want to use for the editor. Not doing this
   * results in multiple instances of our custom nameLink plugin.
   *
   * Note: 'fonts' is an umbrella term for all the default formatting plugins that exist
   * in the editor (e.g 'font-bold', 'font-italic') and we only need to pass this in to
   * include all of them.
   */
  const plugins = [
    'header',
    'fonts',
    'table',
    'image',
    'link',
    'clear',
    'logger',
    'mode-toggle',
    'full-screen',
  ];

  return (
    <MdEditor
      value={value}
      renderHTML={renderHTML}
      style={EDITOR_STYLE}
      config={{ ...EDITOR_CONFIG, htmlClass: htmlClass }}
      onChange={onEditorChange}
      plugins={plugins}
      onImageUpload={handleImageUpload}
    />
  );
}

type PreviewProps = {
  markdown: Markdown;
  className?: string;
};

export function MarkdownPreview(props: PreviewProps) {
  const { markdown, className } = props;
  const classes = useStyles();
  const html = useMemo(() => renderMarkdown(markdown), [markdown]);
  return (
    <div
      className={cx([classes['preview'], className])}
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  );
}

export default function TextBlock({ block, experimentId }: Props) {
  // assert(
  //   module.moduleType === ExperimentModuleTypeEnum.TEXT,
  //   `TextModule must be called with a module of type TEXT, was ${module.moduleType}`,
  // );

  const { isEditing } = useContext(ExperimentDetailContext);

  const [updateModule] = useMutation(MUTATION_UPDATE_EXPERIMENT_MODULE);

  const [description, setDescription] = useState<Markdown | null>(block.text as Markdown);

  // Debounce the request so we don't flood the server with mutations as the user types
  const updateBlockDebounced = useDebounce(() => {
    void updateModule({
      variables: { id: block.id, description },
      refetchQueries: [{ query: QUERY_EXPERIMENT, variables: { id: experimentId } }],
    });
  }, 1000);

  const handleDescriptionChange = useCallback(
    (newDescription: Markdown) => {
      setDescription(newDescription);
      updateBlockDebounced();
    },
    [updateBlockDebounced],
  );

  return (
    <>
      {isEditing ? (
        <MarkdownEditor
          value={(description ?? '') as Markdown}
          onChange={handleDescriptionChange}
          //placeholder="Share your thoughts..."
        />
      ) : (
        description && <MarkdownPreview markdown={description ?? ('' as Markdown)} />
      )}
    </>
  );
}

const useStyles = makeStylesHook({
  text: {
    // Respect new lines in the description
    whiteSpace: 'pre-wrap',
  },
  preview: {
    fontSize: '14px',
    lineHeight: '1.4',
    '& h1': {
      fontSize: '32px',
      padding: '0px',
      border: 'none',
      fontWeight: 700,
      margin: '32px 0',
    },
    '& h2': {
      fontSize: '24px',
      padding: '0px 0',
      border: 'none',
      fontWeight: 700,
      margin: '24px 0',
    },
    '& h3': {
      fontSize: '18px',
      margin: '18px 0',
      padding: '0px 0',
      border: 'none',
    },
    '& p': {
      margin: '8px 0',
    },
    '& a': {
      color: '#0052d9',
    },
    '& a:hover': {
      textDecoration: 'none',
    },
    '& strong': {
      fontWeight: 700,
    },
    '& ol, & ul': {
      paddingLeft: '36px',
    },
    '& li': {
      marginBottom: '4px',
    },
    '& hr': {
      marginTop: '20px',
      marginBottom: '20px',
      border: '0',
      borderTop: '1px solid #eee',
    },
    '& pre': {
      display: 'block',
      backgroundColor: '#f5f5f5',
      padding: '20px',
      lineHeight: '28px',
      borderRadius: '0',
      overflowX: 'auto',
      wordBreak: 'break-word',
    },
    '& code': {
      backgroundColor: '#f5f5f5',
      borderRadius: '0',
      padding: '3px 0',
      margin: '0',
      overflowX: 'auto',
      wordBreak: 'normal',
    },
    '& code:after, & code:before': {
      letterSpacing: 0,
    },
    '& blockquote': {
      position: 'relative',
      margin: '16px 0',
      padding: '5px 8px 5px 30px',
      background: 'none repeat scroll 0 0 rgba(102, 128, 153, 0.05)',
      border: 'none',
      color: '#333',
      borderLeft: '10px solid #d6dbdf',
    },
    '& img, & video': {
      maxWidth: '100%',
    },
    '& table': {
      lineHeight: '1.7',
      maxWidth: '100%',
      overflow: 'auto',
      border: '1px solid #f6f6f6',
      borderCollapse: 'collapse',
      borderSpacing: 0,
      boxSizing: 'border-box',
    },
    '& table td, & table th': {
      wordBreak: 'break-all',
      wordWrap: 'break-word',
      whiteSpace: 'normal',
    },
    '& table tr': {
      border: '1px solid #efefef',
    },
    '& table tr:nth-child(2n)': {
      backgroundColor: 'transparent',
    },
    '& table th': {
      textAlign: 'center',
      fontWeight: 700,
      border: '1px solid #efefef',
      padding: '10px 6px',
      backgroundColor: '#f5f7fa',
      wordBreak: 'break-word',
    },
    '& table td': {
      border: '1px solid #efefef',
      textAlign: 'left',
      padding: '10px 15px',
      wordBreak: 'break-word',
      minWidth: '60px',
    },
  },
});
