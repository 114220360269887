import React, { useState } from 'react';

import PlaceHolderIcon from '@mui/icons-material/Add';
import CalculateIcon from '@mui/icons-material/Calculate';
import ButtonBase from '@mui/material/ButtonBase';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu, { menuClasses } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import Colors from 'common/ui/Colors';
import Tooltip from 'common/ui/components/Tooltip';

//TODO replace with tool implementations
function ComingSoonDialogue({ onClose, open }: { open: boolean; onClose: () => void }) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Tools</DialogTitle>
      <DialogContent>Coming soon!</DialogContent>
    </Dialog>
  );
}

export default function StandaloneToolsButton() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleClickButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [isComingSoonDialogueOpen, setIsComingSoonDialogueOpen] = useState(false);
  function showComingSoonDialogue() {
    setIsComingSoonDialogueOpen(true);
  }

  return (
    <>
      {isComingSoonDialogueOpen ? (
        <ComingSoonDialogue
          onClose={() => setIsComingSoonDialogueOpen(false)}
          open={isComingSoonDialogueOpen}
        />
      ) : null}
      <Tooltip title="Tools">
        <ButtonBase
          data-heap-tracking="breadcrumbs-standalone-tools-button"
          onClick={handleClickButton}
          disableRipple
        >
          <CalculateIcon color="primary" />
        </ButtonBase>
      </Tooltip>

      <StyledMenu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <StyledMenuitem
          onClick={showComingSoonDialogue}
          data-heap-tracking="standalone-tools-list-stock-dilution"
        >
          <ListItemIcon>
            <PlaceHolderIcon />
          </ListItemIcon>
          <StyledTypography>Stock dilution</StyledTypography>
        </StyledMenuitem>
        <StyledMenuitem
          onClick={showComingSoonDialogue}
          data-heap-tracking="standalone-tools-list-absorbance-transmittance"
        >
          <ListItemIcon>
            <PlaceHolderIcon />
          </ListItemIcon>
          <StyledTypography>Absorbance/Transmittance</StyledTypography>
        </StyledMenuitem>
        <StyledMenuitem
          onClick={showComingSoonDialogue}
          data-heap-tracking="standalone-tools-list-mass-molarity"
        >
          <ListItemIcon>
            <PlaceHolderIcon />
          </ListItemIcon>
          <StyledTypography>Mass/Molarity</StyledTypography>
        </StyledMenuitem>
        <StyledMenuitem
          onClick={showComingSoonDialogue}
          data-heap-tracking="standalone-tools-list-plate-planner"
        >
          <ListItemIcon>
            <PlaceHolderIcon />
          </ListItemIcon>
          <StyledTypography>Density Calculator</StyledTypography>
        </StyledMenuitem>
      </StyledMenu>
    </>
  );
}

const StyledMenu = styled(Menu)(({ theme }) => ({
  [`& .${menuClasses.paper}`]: {
    padding: theme.spacing(4),
    borderRadius: 12,
  },
  [`& .${menuClasses.list}`]: {
    padding: 0,
    display: 'grid',
    rowGap: theme.spacing(4),
  },
}));

const StyledMenuitem = styled(MenuItem)({
  borderRadius: 8,
  borderColor: Colors.GREY_20,
  borderWidth: 1,
  borderStyle: 'solid',
  boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.10)',
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 500,
}));
